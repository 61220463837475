import React, { useEffect } from "react";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import UnAuthenticatedRoute from "./UnAuthenticatedRoute";
import AuthenticatedRoute from "./AuthenticatedRoute";
import auth from "./service/auth";
import { useNavigate, useParams } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import GhlAuthentication from "./component/ghlAauthentication";
import ConnectApp from "./component/connectApp";
import CompanySettings from "./component/CompanySettings";
import Payment_links from "./component/Payment_links";
import Virtual_Terminal from "./component/Virtual_Terminal";
import Order from "./component/Order";
import Invoice from "./component/Invoice";
import InvoiceList from "./component/Invoice_List";
import Payment_form from "./component/Payment_form";
import Product from "./component/Product";
import Create_Product from "./component/Create_Product";
import List_Product from "./component/Product_list";
import Logo_upload from "./component/Logo_upload";
import Contacts from "./component/Contacts";
import Connect from './component/connect-gateway';
import Create_Invoice from "./component/Create_Invoice";

function App() {
  
  const Navigate = useNavigate();

  useEffect(() => {
    document.title = 'linked2checkout';
    console.log(window.location.pathname,"window.location.pathname");
    let Connectiondata = auth.isConnection(); 

    if(Connectiondata){
      Navigate("/dashboard");
    }else{
      if (window.location.pathname === "/connectApp") {
        Navigate("/connectApp")
      }else{
        Navigate("/ghlAauthentication/:id");
      }
    }




    // if (!Connectiondata) {
    //   Navigate("/dashboard");
    
    // } else {

    //   if (window.location.pathname === "/") {
    //     Navigate("/dashboard");
        
    //   }
    // }
  }, []);


  return (
    <div className="App">
      <Routes>
        <Route >
          <Route path="/:id" element={<Connect />} />
          <Route path="/dashboard" element={<Invoice />} />
          <Route path="/ghlAauthentication/:id" element={<GhlAuthentication />} />
          <Route path="/connectApp" element={<ConnectApp />} />
          <Route path="/invoice/create" element={<Create_Invoice />} />
          <Route path="/invoice/list" element={<InvoiceList />} />
          <Route path="/product" element={<Product />} />
          <Route path="/product/create_" element={<Create_Product />} />
          <Route path="/product/list_" element={<List_Product />} />
          <Route path="/virtual" element={<Virtual_Terminal />} />
          <Route path="/order" element={<Order />} />
          <Route path="/contact" element={<Contacts />} />
          <Route path="/payment/form" element={<Payment_form />} />
          <Route path="/logo/upload" element={<Logo_upload />} />
          <Route path="/payment/links" element={<Payment_links />} />
          <Route path="/company/setting" element={<CompanySettings />} />
          <Route path="/general/setting_" element={<CompanySettings />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
